import { defineStore } from 'pinia'

export const useDownloadStore = defineStore('downloadStore', {
    state: () => ({
        files: [],
        filenames: [],
        fileCount: 0
    }),
    actions: {
        /**
         *
         * @param {*} file
         */
        pushFile (file) {
            if (!this.files.includes(file)) {
                this.files.push(file)
            }

            let filename = file.split('/')
            filename = filename[filename.length - 1]

            if (!this.filenames.includes(filename)) {
                this.filenames.push(filename)
            }

            this.fileCount = this.filenames.length
        },

        /**
         *
         * @param {*} file
         */
        removeFile (file) {
            let filteredFiles = this.files.filter(element => { return element.includes(file)})

            if (this.filenames.includes(file)) {
                let filename = file.split('/')
                filename = filename[filename.length - 1]
                let filenameIndex = this.filenames.indexOf(filename)
                this.filenames.splice(filenameIndex, 1)
            }

            this.fileCount = this.filenames.length
        }
    }
})
